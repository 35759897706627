import React, { useEffect, useState } from 'react'
import './CategoriesListing.scss'
import HomeHeader from '../../Components/HomeHeader'
import { sample1 } from '../../common/images'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setAllCategories, setAllProducts, setIsLoading } from '../../Redux/globalReducer';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Config/firebase';
import { useDispatch } from 'react-redux';

function CategoriesListing() {
    const { category } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { allCategories, allProducts } = useSelector(state => state.globalReducer);
    const [categories, setCategories] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(category || 'oral_care');
    const [selectedCategoryProducts, setSelectedCategoryProducts] = useState(null);
    const [mobileView, setMobileView] = useState(window.innerWidth < 769);

    useEffect(() => {
        // dispatch(setIsLoading(true));
        setTimeout(() => {
            dispatch(setIsLoading(false));
        }, 3000);
    }, []);

    useEffect(() => {
        if (category) {
            console.log('Category', category);

            setSelectedCategory(category);
        }
    }, [category]);

    useEffect(() => {
        // get all categories
        const getAllCategories = async () => {
            const usersRef = collection(db, "Categories");
            let temp = [];
            getDocs(usersRef).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    temp.push(doc.data());
                });
                console.log(temp);
                dispatch(setAllCategories(temp));
            });
        }

        if (allCategories.length === 0) {
            getAllCategories();
        }

        // get all products
        const getAllProducts = async () => {
            const usersRef = collection(db, "Products");
            let temp = [];
            getDocs(usersRef).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    temp.push(doc.data());
                });
                console.log(temp);
                dispatch(setAllProducts(temp));
                setSelectedCategory(category || 'oral_care');
            });
        }

        if (allProducts.length === 0) {
            getAllProducts();
        }
    }, []);

    useEffect(() => {
        // sort the categories by the sequence number
        if (allCategories && allProducts) {
            let sortedCategories = [...allCategories].sort((a, b) => a.sequence - b.sequence);

            // create a new array with productsCount for each category
            let updatedCategories = sortedCategories.map((cat) => {
                return {
                    ...cat,
                    productsCount: [...allProducts].filter((product) => product.category_ref === cat.category_ref).length
                };
            });

            setCategories(updatedCategories);
        }
    }, [allCategories, allProducts]);

    useEffect(() => {
        if (selectedCategory && allProducts) {
            // filter the products based on the selected category
            let filteredProducts = allProducts.filter((product) => product.category_ref === selectedCategory);
            setSelectedCategoryProducts(filteredProducts);
        }
    }, [selectedCategory, allProducts]);

    const goToProductDetails = (product) => {
        const productName = product.name.toLowerCase().replace(/ /g, '-');
        navigate(`/product-details/${productName}/${product.id}`);
    }

    useEffect(() => {
        // window scroll to top once the page is loaded
        window.scrollTo(0, 0);
    }, []);

    const handleSelectCategory = (category) => {
        navigate(`/categories-listing/${category}`);
        setSelectedCategory(category);
    }

    return (
        <>
            <HomeHeader />
            <div className="text-center mt-3">
                <h2 className="section_title_small">Best Selling Products</h2>
                <h3 className="section_title_big back_gradient_anim_text">Shop By Categories</h3>
            </div>
            <section className='categories_listing_section'>
                <div className="category_selection_wrapper">
                    <div className="cat_tab_wrapper">
                        {
                            categories?.map((cat, index) => {
                                return (
                                    <div key={index} className={`cat_box ${selectedCategory === cat.category_ref ? 'active' : ''}`}
                                        onClick={() => handleSelectCategory(cat.category_ref)}
                                    >
                                        <img className="cat_img" src={cat?.category_image} alt="" />
                                        <span className="cat_title">{cat?.category_name}</span>
                                        <span className="cat_text">{cat?.productsCount} Products</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="cat_content_wrapper row">
                    {
                        selectedCategoryProducts?.map((product, index) => {
                            return (
                                <div className={`product_card ${!mobileView ? 'col-3' : ''}`} onClick={() => goToProductDetails(product)}>
                                    <div className="product_img">
                                        <img src={
                                            product?.images?.length > 0 ? product?.images[0] : 'https://via.placeholder.com/150'
                                        } alt="" srcSet="" />
                                    </div>
                                    <div className="product_details">
                                        <div className="product_rating_box text-center">
                                            <i className="icofont-star highlight_green"></i>
                                            <i className="icofont-star highlight_green"></i>
                                            <i className="icofont-star highlight_green"></i>
                                            <i className="icofont-star highlight_green"></i>
                                            <i className="icofont-star highlight_green"></i>
                                            (5.0)
                                        </div>
                                        <div className="product_title">
                                            {product?.name}
                                        </div>
                                        <div className="product_bottom_wrapper">
                                            <div className="product_price">
                                                <div className="actual_price">
                                                    ₹ {product?.sell_price}</div>
                                                <div className="mrp_price">₹ {
                                                    product?.actual_price
                                                }</div>
                                            </div>
                                            {/* buy now button */}
                                            <button className='buy_now_btn' onClick={() => goToProductDetails(product)}>
                                                Buy Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </>
    )
}

export default CategoriesListing;
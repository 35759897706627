import React from 'react';
import './Blogs.scss';
import HomeHeader from '../../Components/HomeHeader';
import Slider from 'react-slick';
import { blogsList } from '../../common/blogResources';
import { Box } from '@mui/material';

import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { useNavigate } from 'react-router-dom';;


function Blogs({ homeHeader = true }) {

    {/* Blogs */ }

    const blogs = blogsList;

    const settingsBlogs = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        speed: 2000,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        dots: false,
        // add class to control the slider
        className: "blogs_slider",
        lazyLoad: true,
    };


    const navigate = useNavigate();


    const readBlog = (e, id) => {
        console.log('Read Blog ', e, id);
        navigate(`/read-blog/${id}`);
    }

    return (
        <>
            {
                homeHeader &&
                <HomeHeader pageName={'Blogs'} />
            }

            <div className="container-fluid blogs_container mt-3">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <h2 className="section_title_small">Our Blogs</h2>
                        <h3 className='section_title_big back_gradient_anim_text'>Expert Opinion</h3>
                    </div>
                </div>
                <div className="row blogs_row">

                    {/* <Slider {...settingsBlogs}> */}
                    {blogs.map((blog) => {
                        return (
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 blog_column">
                                <Box className="blog_box" key={'blog_' + blog.id} sx={{ height: '100%' }}>
                                    <div className="blog_image">
                                        <img src={blog.image} alt="blog" />
                                        {
                                            blog.author &&
                                            <div className="blog_author" >
                                                <h3 className='author_name'>{blog.author}</h3>
                                                {
                                                    blog.position &&
                                                    <span className='author_position'>{blog.position}</span>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="blog_content">
                                        <h3>{blog.name}</h3>
                                        <p>{blog.text}</p>
                                    </div>
                                    <div className="blog_footer">
                                        <div className="read_more" onClick={(e) => readBlog(e, blog.id)}>
                                            <ReadMoreIcon /> Read More
                                        </div>
                                    </div>
                                </Box>
                            </div>
                            // </>
                        );
                    })
                    }
                    {/* </Slider> */}


                </div>

            </div>
        </>
    );
}

export default Blogs;
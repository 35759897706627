import { pdf_blog_1, pdf_blog_2, pdf_FOFT_blog_1, pdf_FOFT_blog_2, pdf_FOFT_blog_3, pdf_FOFT_blog_4, pdf_FOFT_blog_5, pdf_FOFT_blog_6, pdf_FOFT_blog_7 } from './documents';
import { blog_1, blog_2, FOFT_blog_1, FOFT_blog_2, FOFT_blog_3, FOFT_blog_4, FOFT_blog_5, FOFT_blog_6, FOFT_blog_7 } from './images';


export const blogsList = [
    {
        id: 'impacts-of-micro-plastic-on-human-health',
        image: blog_1,
        file: pdf_blog_1,
        text: "The potential impacts of micro-plastics on human health are a topic of much scientific investigation. Micro-plastics are tiny pieces of plastic that are less than 5mm in size, and they are widely distributed in the environment, including in the air, water, and soil.",
        name: "Impacts of Micro-plastic on Human Health...",
        author: "",
        position: "",
    },
    {
        id: 'what-is-bpa',
        image: blog_2,
        file: pdf_blog_2,
        text: "Bisphenol a (bpa) is a chemical that is widely used in the production of polycarbonate plastics and epoxy resins. Such as in the production of some plastic products, including food and beverage containers, in the lining of canned foods as well as in ther consumer products such as electronic equipment and medical devices.",
        /**
         * There is growing concern about the potential health mpacts of bpa exposure, as studies have shown that the chemical can leach from the plastic into the food or beverages it contains.
         */
        name: "WHAT IS BPA",
        author: "",
        position: "",
    },
    {
        id: 'what-is-friends-of-turtles',
        image: FOFT_blog_1,
        file: pdf_FOFT_blog_1,
        text: "In a world increasingly aware of the environmental impact of our daily choices, Friends of Turtles stands out as a beacon of hope and sustainability.",
        name: "What is Friends of Turtles?",
        author: "",
        position: "",
    },
    {
        id: 'embracing-sustainability-our-journey-at-fof-turtles',
        image: FOFT_blog_2,
        file: pdf_FOFT_blog_2,
        text: "In a world where everything seems to move at lightning speed, we often forget the importance of our planet. At FOF Turtles, we’re passionate about making a difference—one step at a time.",
        name: "Embracing Sustainability: Our Journey at FOF Turtles",
        author: "",
        position: "",
    },
    {
        id: 'honouring-bold-environmental-leadership-unep-2024-champions-of-the-earth',
        image: FOFT_blog_3,
        file: pdf_FOFT_blog_3,
        text: "The United Nations Environment Program (UNEP) has announced the winners of the prestigious 2024 Champions of the Earth awards, recognizing six extraordinary individuals and organizations whose groundbreaking work is shaping the future of environmental sustainability. ",
        name: "Honouring Bold Environmental Leadership: UNEP's 2024 Champions of the Earth",
        author: "",
        position: "",
    },
    {
        id: 'bottled-water-a-plastic-soup-unveiling-the-microplastic-reality',
        image: FOFT_blog_4,
        file: pdf_FOFT_blog_4,
        text: "Imagine trusting a sip of bottled water only to unknowingly consume a cocktail of microplastics. Disturbing, isn’t it? Yet, a landmark 2018 study conducted by Orb Media in collaboration with researchers at the State University of New York at Fredonia revealed exactly this unsettling truth.",
        name: "Bottled Water: A Plastic Soup? Unveiling the Microplastic Reality",
        author: "",
        position: "",
    },
    {
        id: 'world-soil-day-safeguarding-natures-silent-lifeline',
        image: FOFT_blog_5,
        file: pdf_FOFT_blog_5,
        text: "This single fact highlights the intricate complexity and immense importance of soil. More than just the ground beneath our feet, soil is the lifeline of ecosystems, agriculture, and biodiversity.",
        name: "World Soil Day: Safeguarding Nature’s Silent Lifeline",
        author: "",
        position: "",
    },
    {
        id: 'building-a-sustainable-future-how-technology-and-innovation-are-transforming-our-planet',
        image: FOFT_blog_6,
        file: pdf_FOFT_blog_6,
        text: "Introduction Sustainability is no longer just a goal—it’s an urgent necessity for the planet's survival. Advances in science and technology are providing groundbreaking solutions to some of the world’s most pressing environmental challenges.",
        name: "Building a Sustainable Future: How Technology and Innovation Are Transforming Our Planet",
        author: "",
        position: "",
    },
    {
        id: 'record-breaking-october-2024-examining-indias-heat-wave-phenomenon',
        image: FOFT_blog_7,
        file: pdf_FOFT_blog_7,
        text: "In October 2024, India experienced its hottest October on record, with temperatures surpassing those of any previous October since the Indian Meteorological Department (IMD) began collecting data in 1901.",
        name: "Record-Breaking October 2024: Examining India’s Heat Wave Phenomenon",
        author: "",
        position: "",
    }
]; 
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import HomeOld from './screens/HomeOld/HomeOld';
import About from './screens/About/About';
import ProductDetails from './screens/ProductDetails/ProductDetails';
import { Provider } from 'react-redux';
import store from './Redux/store';
import { auth, db } from './Config/firebase';
import { setUserInfo } from './Redux/globalReducer';
import { doc, getDoc } from 'firebase/firestore';
import Login from './screens/Login/Login';
import Register from './screens/Register/Register';
import Cart from './screens/Cart/Cart';
import './style.scss';
import Review from './screens/Review/Review';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Payment from './screens/Payment/Payment';
import MyOrders from './screens/MyOrders/MyOrders';
import CustomLoader from './Components/CustomLoader';
import TAndC from './screens/TAndC/TAndC';
import PrivacyPolicy from './screens/PrivacyPolicy/PrivacyPolicy';
import RefundPolicy from './screens/RefundPolicy/RefundPolicy';
import ContactUs from './screens/ContactUs/ContactUs';
import TermsOfSales from './screens/TermsOfSales/TermsOfSales';
import ShippingPolicy from './screens/ShippingPolicy/ShippingPolicy';
import CategoriesListing from './screens/CategoriesListing/CategoriesListing';
import OurTeam from './screens/OurTeam/OurTeam';
import Home from './screens/Home/Home';
import BulkOrder from './screens/BulkOrder/BulkOrder';
import ViewPDF from './common/Components/ViewPDF/ViewPDF';
import Blogs from './screens/Blogs/Blogs';
import CustomizedOrder from './screens/CustomizedOrder/CustomizedOrder';

function App() {

  // Listen for authentication state changes
  auth.onAuthStateChanged(async (user) => {
    if (user) {
      console.log('User is signed in');
      await getDoc(doc(db, "Users", user.uid)).then((doc) => {
        if (doc.exists()) {
          console.log("Document data:", doc.data());
          store.dispatch(setUserInfo(doc.data()));
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          store.dispatch(setUserInfo({}));
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    } else {
      // No user is signed in
      console.log('No user is signed in');
      store.dispatch(setUserInfo({}));
    }
  });


  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          {/* <Route path="/home-old" element={<HomeOld />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/product-details/:productName/:productId" element={< ProductDetails />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/review' element={<Review />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/my-orders' element={<MyOrders />} />
          <Route path='/terms-and-conditions' element={<TAndC />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/terms-of-sales' element={<TermsOfSales />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/shipping-policy' element={<ShippingPolicy />} />
          <Route path='/home' element={<Home />} />
          <Route path='/categories-listing/:category' element={<CategoriesListing />} />
          <Route path='/our-team' element={<OurTeam />} />
          <Route path='/bulk-order' element={<BulkOrder />} />
          <Route path='/customizedOrder' element={<CustomizedOrder />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/read-blog/:id' element={<ViewPDF />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter >
      <ToastContainer />
      <CustomLoader />

    </Provider>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import './ViewPDF.scss';
import { blogsList } from '../../blogResources';
import HomeHeader from '../../../Components/HomeHeader';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import RssFeedIcon from '@mui/icons-material/RssFeed';

import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const ViewPDF = ({ homeHeader = true }) => {
    const { id } = useParams();

    const [fileURL, setFileURL] = useState('');
    const [blogName, setBlogName] = useState('');
    const navigate = useNavigate();

    const location = useLocation();

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        viewBlog(id);
        // Hide scroll for root element
        document.documentElement.style.overflow = 'hidden';

        // Cleanup function to restore scroll
        return () => {
            document.documentElement.style.overflow = 'auto';
        };
    }, [id]);


    const viewBlog = async (docId) => {
        console.log('docId', docId);

        const tempBlog = blogsList.find(blog => blog.id === docId);

        if (tempBlog) {
            setBlogName(tempBlog.name);
            setFileURL(tempBlog.file);
        } else {
            setFileURL('');
        }

    };

    const goToBlogs = () => {
        navigate('/blogs');
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    return (

        <>
            {homeHeader &&
                <HomeHeader />
            }
            <div className="blogs_viewer">
                <div className="view_blog_container w-100">
                    <div className='blog_breadchrumb'>
                        <RssFeedIcon />
                        <div className='breadchrumb_items'>
                            <span className='parent_menu' onClick={goToBlogs}>{'Blogs > '} </span> <span className='child_menu'>{blogName}</span>
                            {/* <span className='separator'> {'>'} </span> */}
                        </div>
                    </div>
                </div>
                {
                    fileURL &&
                    <div className='pdf_container'>
                        <embed src={fileURL + '#toolbar=1'} type="application/pdf" width="100%" height="100%" />
                        {/* <Document file={fileURL} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                        </Document> */}
                    </div>
                }
            </div>
        </>
    );
};

export default ViewPDF;